class translateService {
    constructor($rootScope, $http, $q, $timeout) {
        'ngInject';

        this.$http = $http;
        this.deferred = $q.defer();
        this.promise = this.deferred.promise;
        this.$rootScope = $rootScope;
        this.loaded = false;
        this.$q = $q;
        this.$timeout = $timeout;

        this.setCurrentLang(
            document.getElementsByTagName('html')[0].attributes.lang.value || 'pl'
        );
    }

    getCurrentTranslations() {
        console.log(`translateService::getCurrentTranslations -> ${this.currentLang}: load translations from JSON file!`)

        const cacheBuster = `cb=${new Date().getTime()}`;

        return this.$http.get(
            `/build/i18n/${this.currentLang}.json?${cacheBuster}`
        );
    }

    setCurrentLang(lang) {
        this.translations = {};
        this.currentLang = lang;
        this.loaded = false;

        setTimeout(() => {
            if(typeof(translations) != 'undefined') {
                this.translations = translations;
                this.deferred.resolve(true);
                this.$rootScope.$apply();
            } else {
                this.getCurrentTranslations().then(
                    (response) => {
                        this.translations = response.data;
                        this.deferred.resolve(true);
                        this.loaded = true;
                    },
                    () => {
                        console.log(`translateService::getCurrentTranslations -> ${this.currentLang}: can't load translations JSON file!`)
                    }
                )
            }
        }, 50);
    }

    translate(slug, values) {
        if (typeof slug !== 'string') {
            return undefined;
        }

        const parts = slug.split('.');

        let translate = this.translations;

        for (const part of parts) {
            if (typeof translate[part] === 'undefined') {
                return slug;
            }

            translate = translate[part];
        }

        if (values && typeof values === 'object') {
            const keys = Object.keys(values).map(k => `:${k}`);
            const matches = Object.values(values);

            if (!keys || !matches || keys.length < 1 || keys.length != matches.length) {
                return translate;
            }

            for (let i = 0; i < keys.length; i++) {
                translate = translate.replace(keys[i], matches[i]);
            }
        }

        return translate;
    }

    t(slug, values) {
        return this.translate(slug, values);
    }

    tp(slug, values) {
        const deferred = this.$q.defer();
        const promise = deferred.promise;

        if (this.loaded) {
            this.$timeout(() => {
                deferred.resolve(this.t(slug, values));
            }, 500);
        } else {
            this.promise.then(() => {
                deferred.resolve(this.t(slug, values));
            })
        }

        return promise;
    }
}

translateService.$inject = ['$rootScope', '$http', '$q', '$timeout'];
export default translateService;
