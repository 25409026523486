import WorldMapView from '../views/worldMap.view.html?raw';

export default class WorldMap {
    constructor(domainsService, translateService) {
        this.domainsService = domainsService;
        this.translate = translateService;

        this.restrict = 'E'
        this.template = WorldMapView;
        this.scope = {
            regions: '=worldMap',
            active: '=?active',
            extensions: '=extensions',
            limit: '@?limit'
        }
    }

    link(scope, elm, attrs) {
        scope.limit = 28;
        scope.showAll = false;
        scope.domainsService = this.domainsService;
        scope.domainsByRegions = scope.domainsService.getExtensionsListByRegions();
        scope.activeRegion = 'europe';
        scope.listOfDomainsSelectedRegion = scope.domainsByRegions[scope.activeRegion];
        scope.countryFlagExceptions = {
            'eu.com': 'eu',
            'ею': 'eu',
            'ευ': 'eu'
        };
        scope.regionsDescriptions = {
            'africa': this.translate.t('common.regions.africa'),
            'asia': this.translate.t('common.regions.asia'),
            'europe': this.translate.t('common.regions.europe'),
            'middle-east': this.translate.t('common.regions.middle_east'),
            'north-america': this.translate.t('common.regions.north_america'),
            'south-america': this.translate.t('common.regions.south_america'),
            'australia': this.translate.t('common.regions.australia'),
        }

        scope.getFlagCode = function(domain) {
            if (domain.country !== null && domain.country.iso_code !== null) {
                return domain.country.iso_code.toLowerCase();
            }

            if (scope.countryFlagExceptions.hasOwnProperty(domain.extension_idn) === true) {
                return scope.countryFlagExceptions[domain.extension_idn].toLowerCase();
            }

            return domain.extension_idn.toLowerCase();
        };

        scope.select = function (name) {
            scope.showAll = false;
            scope.activeRegion = name;
            scope.listOfDomainsSelectedRegion = scope.domainsByRegions[scope.activeRegion];
        };

        scope.toggleShow = function () {
            scope.showAll = !scope.showAll;
        }
    }

    static directiveFactory(domainsService) {
        return new WorldMap(domainsService);
    }
}
